<template>
  <div class="page-wrapper">
    <section v-if="$route && $route.query && $route.query.success === '1'" class="form-wrap">
      <h1 class="big-title">{{ $t('thanks-title') }}</h1>
      <p class="regular-text">{{ $t('thanks-desc') }}</p>
      <a href="/" class="cta-bt">
        <p>{{ $t('thanks-cta') }}</p>
      </a>
    </section>
    <section v-else class="form-wrap">
      <h1 class="big-title">{{ $t('contact-title') }}</h1>
      <p class="regular-text">{{ $t('contact-desc') }}</p>

      <form action="/contact?success=1" name="nouveau-compte-showadz" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="nouveau-compte-showadz" />

        <div class="input-label-wrap" :class="{ filled: client.name }">
          <input v-model="client.name" name="name" type="text" class="label-focus" required />
          <p class="label">{{ $t('form-name') }}</p>
        </div>

        <div class="input-label-wrap" :class="{ filled: client.entreprise }">
          <input v-model="client.entreprise" name="entreprise" type="text" class="label-focus" required />
          <p class="label">{{ $t('form-business') }}</p>
        </div>

        <div class="input-label-wrap" :class="{ filled: client.email }">
          <input v-model="client.email" name="email" type="text" class="label-focus" required />
          <p class="label">{{ $t('form-mail') }}</p>
        </div>

        <div class="input-label-wrap" :class="{ filled: client.forfait }">
          <select v-model="client.forfait" name="forfait" class="label-focus">
            <option value=""></option>
            <option value="Offre de lancement">{{ $t('form-interest-1') }}</option>
            <option value="Forfait sur mesure">{{ $t('form-interest-2') }}</option>
          </select>
          <p class="label">{{ $t('form-interest') }}</p>
        </div>

        <button type="submit" class="cta-bt" :class="{ 'is-loading': isSaving }">
          <p>{{ $t('form-send') }}</p>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Expérience client',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        { property: 'og:url', content: `https://showadz.io` },
        { property: 'og:type', content: `Website` },
        {
          property: 'og:description',
          content: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/og-image-showadz.jpg?alt=media&token=359400dd-56d7-48f4-8ae5-04ae30ecda7c',
        },
      ],
    }
  },
  data() {
    return {
      isSaving: false,
      client: {
        profileImg: '',
        name: '',
        email: '',
        password1: '',
        password2: '',
        entreprise: '',
        forfait: '',
        isArchived: false,
      },
    }
  },
  methods: {},
  computed: mapState('app', ['appTitle']),
  mounted() {
    if (window.location.href.includes('showadz.com')) {
      window.location.href = 'https://showadz.io/'
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typographie.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';

.form-wrap {
  width: 570px;
  margin: 5vw auto;
  text-align: center;
  margin-bottom: 10vw;

  .regular-text {
    margin-bottom: 2vw;
    margin-top: 5px;
  }

  .label {
    font-size: 14px;
    color: #637082;
    font-weight: 400;
  }

  .label-focus {
    font-weight: 400;
    appearance: none;
  }

  .input-label-wrap {
    margin-bottom: 10px;
    width: 350px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .input-file-wrap {
    display: inline-block;
  }

  .client-photo-label {
    margin: 10px 0 20px 0;
    color: #637082;
  }

  .cta-bt {
    padding: 0;
    margin: 0;
    border: 0;
    padding: 15px 20px;
    margin-top: 20px;
    display: inline-block;
    font-family: sofia-pro, sans-serif;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 5vw 8vw;
    .input-label-wrap {
      width: 100%;
    }
    .regular-text {
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
}
</style>
