<template>
  <footer class="main-footer">
    <div class="img-wrap">
      <div class="cta-title">
        <p>{{ $t('footer-title') }}</p>
        <h5 class="medium-title">{{ $t('footer-desc') }}</h5>
        <div class="cta-bt" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/showadz/30min'});return false;">
          <p>{{ $t('footer-cta') }}</p>
        </div>
      </div>
      <img src="@/assets/img/jnv.jpg" alt="J&V" class="jnv-img">
    </div>

    <div class="fondateurs">
      <div class="fondateur left" v-html="$t('footer-vincent')"></div>
      <a href="https://jnv.dev/" target="_blank"><img src="@/assets/img/jnv-logo.svg" alt="J&V logo" class="jnv-logo"></a>
      <div class="fondateur right" v-html="$t('footer-julien')"></div>
    </div>

    <div class="bottom">
      <p>{{ $t('footer-copyrights') }}</p>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/typographie.scss';

.main-footer {
  width: 100%;
  background-color: $dark-blue;
  padding: 5vw 8vw;
  margin-top: 5vw;
  position: relative;

  .img-wrap {
    width: 100%;
    margin-top: -10vw;
    position: relative;

    .cta-title {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      p {
        color: #fff;
        font-weight: 300;
        font-size: 18px;
      }

      .cta-bt {
        display: inline-block;
        padding: 15px 20px 18px 20px;
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .medium-title {
        color: #fff;
        margin: 0;
        padding: 2vw 15vw;
      }
    }
  }

  .jnv-img {
    width: 100%; 
  }

  .fondateurs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 0;

    .jnv-logo {
      position: relative;
      left: -15px;
      width: 80px;
    }

    .fondateur {
      position: relative;
      color: #fff;
      &.left {
        padding-left: 20px;
        p {
          text-align: left;
        }
        &:before {
          content: '';
          width: 5px;
          height: 65px;
          display: block;
          background: linear-gradient(to right, #2bb0c5, #6bd8ba);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &.right {
        padding-right: 20px;
        p {
          text-align: right;
        }
        &:before {
          content: '';
          width: 5px;
          height: 65px;
          display: block;
          background: linear-gradient(to right, #2bb0c5, #6bd8ba);
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      p {
        color: $dark-grey;
        font-size: 14px;
        span {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 20px;
    position: absolute;
    left: 0;
    bottom: 0;

    p {
      color: $dark-blue;
      font-size: 14px;

      a {
        color: $dark-blue;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 5vw 5vw;
  }

  @media screen and (max-width: 1100px) {

    .img-wrap {
      .cta-title {
        .medium-title {
          padding: 2vw 10vw;
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }

    .bottom {
      padding: 10px;

      p {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .img-wrap {
      margin-bottom: 200px;
      .cta-title {
        top: 120%;
        transform: translate(-50%,0);
        .medium-title {
          color: #fff;
          margin: 0;
          padding: 4vw 0vw;
        }
        .cta-bt {
          padding: 12px 15px 12px 15px;
          margin-bottom: 25px;

          p {
            font-size: 14px;
          }
        }
      }
    }
    .fondateurs {
      padding: 5vw 0 60px 0;
      .jnv-logo {
        width: 40px;
        left: -8px;
      }
      .fondateur {
        font-size: 12px !important;
      }
    }
  }
}

</style>
