<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import { isNil } from 'lodash'

import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NavBar, FooterBar },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'fr'
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">

	* {
	  box-sizing: border-box;
	}
	
	body {
		margin: 0;
		font-family: sofia-pro, sans-serif;
		background-color: #F9F9FB;
		padding-top: 80px;
		@media screen and (max-width: 1024px) {
			padding-top: 64px;
		}
		@media screen and (max-width: 915px) {
			padding-top: 0;
		}
	}

	img {
	    display: block;
	    height: auto;
	}

	a {
		text-decoration: none;
	}

	h1,
	h2,
	h3,
	h4,
	p {
		margin: 0;
		padding: 0;
	}

</style>
