<template>
  <div class="page-wrapper">
    <section class="hero-2">
      <div class="data">
        <h1 class="big-title" v-html="$t('intro-title')"></h1>
        <p class="regular-text">{{ $t('intro-desc') }}</p>
        <a href="https://app.showadz.io/create-account" target="_blank" rel="noopener noreferrer" class="cta-bt">
          <p>{{ $t('cta') }}</p>
        </a>
        <div class="logos">
          <img src="@/assets/img/facebook.png" alt="Facebook" />
          <img src="@/assets/img/google.png" alt="Google" />
        </div>
      </div>
      <div class="browser">
        <header>
          <div class="circle red"></div>
          <div class="circle yellow"></div>
          <div class="circle green"></div>
        </header>
        <div class="content">
          <div class="slide">
            <div class="video-overlay"></div>
            <video autoplay playsinline muted loop>
              <source src="@/assets/video/showadz-presentation.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>

    <section id="avantages" class="arguments-wrap">
      <div class="argument">
        <img src="@/assets/img/argument-1.jpg" alt="" />
        <h3 class="medium-title" v-html="$t('argument-1-title')"></h3>
        <p class="regular-text">
          {{ $t('argument-1-desc') }}
        </p>
      </div>
      <div class="argument">
        <img src="@/assets/img/argument-2.jpg" alt="" />
        <h3 class="medium-title" v-html="$t('argument-2-title')"></h3>
        <p class="regular-text">
          {{ $t('argument-2-desc') }}
        </p>
      </div>
      <div class="argument">
        <img src="@/assets/img/argument-3.jpg" alt="" />
        <h3 class="medium-title" v-html="$t('argument-3-title')"></h3>
        <p class="regular-text">
          {{ $t('argument-3-desc') }}
        </p>
      </div>
    </section>

    <section class="temoignages">
      <img src="@/assets/img/swipe.svg" alt="Swipe" class="swipe" />
      <flickity ref="flickity" class="carousel" :options="flickityOptions">
        
        <!-- <div class="carousel-cell">
          <div class="img">
            <div class="gradient"></div>
            <img src="@/assets/img/dots-blanc.svg" alt="Dots" class="dots" />
            <img src="@/assets/img/testimonials/audrey-racine.jpg" alt="Audrey Racine" class="user" />
          </div>
          <div class="data">
            <div class="top">
              <div class="title">
                <h3 class="medium-title">Audrey Racine</h3>
                <a href="https://www.linkedin.com/in/audreysracine/" target="_blank"
                  ><img src="@/assets/img/testimonials/linkedin.svg" alt="Linkedin"
                /></a>
              </div>
              <div class="top-argument">
                <div class="argument">
                  <p class="number">250</p>
                  <p class="regular-text" v-html="$t('testimonial-hours')"></p>
                </div>
                <div class="argument">
                  <p class="number">12</p>
                  <p class="regular-text" v-html="$t('testimonial-clients')"></p>
                </div>
                <div class="argument">
                  <div class="stars-wrap">
                    <div class="stars">
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                    </div>
                    <p class="note">4.8</p>
                  </div>
                  <p class="regular-text" v-html="$t('testimonial-score')"></p>
                </div>
              </div>
              <p class="testimonial-txt regular-text">
                {{ $t('testimonial-audrey') }}
              </p>
            </div>
            <div class="bottom">
              <a href="https://dialekta.com/fr/" target="_blank"><img src="@/assets/img/testimonials/dialekta.png" alt="Dialekta" class="company" /></a>
              <div class="txt">
                <p class="regular-text poste">{{ $t('testimonial-audrey-poste') }}</p>
                <p class="regular-text">Dialekta</p>
              </div>
            </div>
          </div>
        </div> -->

        <div class="carousel-cell">
          <div class="img">
            <div class="gradient"></div>
            <img src="@/assets/img/dots-blanc.svg" alt="Dots" class="dots" />
            <img src="@/assets/img/testimonials/benoit-havel.jpg" alt="Benoit Havel" class="user" />
          </div>
          <div class="data">
            <div class="top">
              <div class="title">
                <img src="@/assets/img/testimonials/benoit-havel.jpg" alt="Benoit Havel" class="user" />
                <h3 class="medium-title">Benoit Havel</h3>
                <a href="https://www.linkedin.com/in/benoit-havel/" target="_blank"><img src="@/assets/img/testimonials/linkedin.svg" alt="Linkedin" /></a>
              </div>
              <div class="top-argument">
                <div class="argument">
                  <p class="number">120</p>
                  <p class="regular-text" v-html="$t('testimonial-hours')"></p>
                </div>
                <div class="argument">
                  <p class="number">24</p>
                  <p class="regular-text" v-html="$t('testimonial-clients')"></p>
                </div>
                <div class="argument">
                  <div class="stars-wrap">
                    <div class="stars">
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                    </div>
                    <p class="note">4.8</p>
                  </div>
                  <p class="regular-text" v-html="$t('testimonial-score')"></p>
                </div>
              </div>
              <p class="testimonial-txt regular-text">
                {{ $t('testimonial-benoit') }}
              </p>
            </div>
            <div class="bottom">
              <a href="https://nomadcommunication.com/" target="_blank"
                ><img src="@/assets/img/testimonials/nomade-communication.png" alt="Dialekta" class="company"
              /></a>
              <div class="txt">
                <p class="regular-text poste">{{ $t('testimonial-benoit-poste') }}</p>
                <p class="regular-text">Nomad Communication</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="img">
            <div class="gradient"></div>
            <img src="@/assets/img/dots-blanc.svg" alt="Dots" class="dots" />
            <img src="@/assets/img/testimonials/sophie-naert.jpg" alt="Sophie Naert" class="user" />
          </div>
          <div class="data">
            <div class="top">
              <div class="title">
                <img src="@/assets/img/testimonials/sophie-naert.jpg" alt="Sophie Naert" class="user" />
                <h3 class="medium-title">Sophie Naert</h3>
                <a href="https://www.linkedin.com/in/sophie-naert-37b81061/" target="_blank"
                  ><img src="@/assets/img/testimonials/linkedin.svg" alt="Linkedin"
                /></a>
              </div>
              <div class="top-argument">
                <div class="argument">
                  <p class="number">100</p>
                  <p class="regular-text" v-html="$t('testimonial-hours')"></p>
                </div>
                <div class="argument">
                  <p class="number">12</p>
                  <p class="regular-text" v-html="$t('testimonial-clients')"></p>
                </div>
                <div class="argument">
                  <div class="stars-wrap">
                    <div class="stars">
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                    </div>
                    <p class="note">4.8</p>
                  </div>
                  <p class="regular-text" v-html="$t('testimonial-score')"></p>
                </div>
              </div>
              <p class="testimonial-txt regular-text">
                {{ $t('testimonial-sophie') }}
              </p>
            </div>
            <div class="bottom">
              <a href="https://www.linkedin.com/in/sophie-naert-37b81061/" target="_blank"
                ><img src="@/assets/img/testimonials/panorama-digital.png" alt="Dialekta" class="company"
              /></a>
              <div class="txt">
                <p class="regular-text poste">{{ $t('testimonial-sophie-poste') }}</p>
                <p class="regular-text">Panorama Digital</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="img">
            <div class="gradient"></div>
            <img src="@/assets/img/dots-blanc.svg" alt="Dots" class="dots" />
            <img src="@/assets/img/testimonials/manon-simenel.jpg" alt="Manon Simenel" class="user" />
          </div>
          <div class="data">
            <div class="top">
              <div class="title">
                <img src="@/assets/img/testimonials/manon-simenel.jpg" alt="Manon Simenel" class="user" />
                <h3 class="medium-title">Manon Simenel</h3>
                <a href="https://www.linkedin.com/in/manon-simenel/" target="_blank"
                  ><img src="@/assets/img/testimonials/linkedin.svg" alt="Linkedin"
                /></a>
              </div>
              <div class="top-argument">
                <div class="argument">
                  <p class="number">50+</p>
                  <p class="regular-text" v-html="$t('testimonial-hours')"></p>
                </div>
                <div class="argument">
                  <p class="number">12</p>
                  <p class="regular-text" v-html="$t('testimonial-clients')"></p>
                </div>
                <div class="argument">
                  <div class="stars-wrap">
                    <div class="stars">
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                    </div>
                    <p class="note">4.5</p>
                  </div>
                  <p class="regular-text" v-html="$t('testimonial-score')"></p>
                </div>
              </div>
              <p class="testimonial-txt regular-text">
                {{ $t('testimonial-manon') }}
              </p>
            </div>
            <div class="bottom">
              <a href="https://www.leeroy.ca/" target="_blank"><img src="@/assets/img/testimonials/leeroy.png" alt="Dialekta" class="company" /></a>
              <div class="txt">
                <p class="regular-text poste">{{ $t('testimonial-manon-poste') }}</p>
                <p class="regular-text">Leeroy Agence Créative</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="img">
            <div class="gradient"></div>
            <img src="@/assets/img/dots-blanc.svg" alt="Dots" class="dots" />
            <img src="@/assets/img/testimonials/colas-de-rienzo.jpg" alt="Colas De Rienzo" class="user" />
          </div>
          <div class="data">
            <div class="top">
              <div class="title">
                <img src="@/assets/img/testimonials/colas-de-rienzo.jpg" alt="Colas De Rienzo" class="user" />
                <h3 class="medium-title">Colas de Rienzo</h3>
                <a href="https://www.linkedin.com/in/digital-marketing-web-analytics/" target="_blank"
                  ><img src="@/assets/img/testimonials/linkedin.svg" alt="Linkedin"
                /></a>
              </div>
              <div class="top-argument">
                <div class="argument">
                  <p class="number">75</p>
                  <p class="regular-text" v-html="$t('testimonial-hours')"></p>
                </div>
                <div class="argument">
                  <p class="number">8</p>
                  <p class="regular-text" v-html="$t('testimonial-clients')"></p>
                </div>
                <div class="argument">
                  <div class="stars-wrap">
                    <div class="stars">
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                      <img src="@/assets/img/testimonials/star.svg" alt="star" />
                    </div>
                    <p class="note">4.5</p>
                  </div>
                  <p class="regular-text" v-html="$t('testimonial-score')"></p>
                </div>
              </div>
              <p class="testimonial-txt regular-text">{{ $t('testimonial-colas') }}</p>
            </div>
            <div class="bottom">
              <a href="https://sailitics.com/" target="_blank"><img src="@/assets/img/testimonials/sailitics.png" alt="Dialekta" class="company" /></a>
              <div class="txt">
                <p class="regular-text poste">{{ $t('testimonial-colas-poste') }}</p>
                <p class="regular-text">Sailitics</p>
              </div>
            </div>
          </div>
        </div>
      </flickity>
      <div class="arrows-wrap">
        <button class="left" @click="previous()"><img src="@/assets/img/testimonials/arrow-left.svg" alt="left" /></button>
        <button class="right" @click="next()"><img src="@/assets/img/testimonials/arrow-right.svg" alt="right" /></button>
      </div>
    </section>

    <section class="personas">
      <h2 class="big-title">{{ $t('approche-title') }}</h2>
      <img src="@/assets/img/wave.svg" alt="wave" class="wave" />
      <p class="regular-text">
        {{ $t('approche-desc') }}
      </p>
      <img src="@/assets/img/personas.png" alt="Notre public cible" class="desktop" v-if="$route.meta.lang === 'fr'" />
      <img src="@/assets/img/personas-mobile.png" alt="Notre public cible" class="mobile" v-if="$route.meta.lang === 'fr'" />
      <img src="@/assets/img/personas-en.png" alt="Notre public cible" class="desktop" v-if="$route.meta.lang === 'en'" />
      <img src="@/assets/img/personas-mobile-en.png" alt="Notre public cible" class="mobile" v-if="$route.meta.lang === 'en'" />
    </section>

    <section class="strategy">
      <div class="strat-wrap">
        <h2 class="big-title">{{ $t('menu-2') }}</h2>
        <img src="@/assets/img/wave.svg" alt="wave" class="wave" />
        <h3 class="big-title">{{ $t('features-desc') }}</h3>
      </div>
    </section>

    <section id="features" class="features-wrap">
      <div class="feature">
        <img src="@/assets/img/feature-1.png" alt="Gérez vos clients et campagnes" />
        <h3 class="medium-title">{{ $t('features-1-title') }}</h3>
        <p class="regular-text">{{ $t('features-1-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-2.png" alt="Créez vos publicités" />
        <h3 class="medium-title">{{ $t('features-2-title') }}</h3>
        <p class="regular-text">
          {{ $t('features-2-desc') }}
        </p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-10.png" alt="Recadrer vos images" />
        <h3 class="medium-title">{{ $t('features-3-title') }}</h3>
        <p class="regular-text">{{ $t('features-3-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-3.png" alt="Prévisualisez en contexte" />
        <h3 class="medium-title">{{ $t('features-4-title') }}</h3>
        <p class="regular-text">{{ $t('features-4-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-4.png" alt="Partagez à vos clients" />
        <h3 class="medium-title">{{ $t('features-5-title') }}</h3>
        <p class="regular-text">{{ $t('features-5-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-5.png" alt="Recevez des commentaires" />
        <h3 class="medium-title">{{ $t('features-6-title') }}</h3>
        <p class="regular-text">
          {{ $t('features-6-desc') }}
        </p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-7.png" alt="Connectez-vous avec FBM" />
        <h3 class="medium-title">{{ $t('features-7-title') }}</h3>
        <p class="regular-text">{{ $t('features-7-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-8.png" alt="Exportez pour Google Ads Editor" />
        <h3 class="medium-title">{{ $t('features-8-title') }}</h3>
        <p class="regular-text">{{ $t('features-8-desc') }}</p>
      </div>
      <div class="feature">
        <img src="@/assets/img/feature-9.png" alt="Travailler en équipe" />
        <h3 class="medium-title">{{ $t('features-9-title') }}</h3>
        <p class="regular-text">{{ $t('features-9-desc') }}</p>
      </div>
    </section>

    <section id="pricing" class="pricing">
      <h2 class="big-title">{{ $t('pricing-title') }}</h2>
      <img src="@/assets/img/wave.svg" alt="wave" class="wave" />
      <p class="regular-text">{{ $t('pricing-desc') }}</p>
      <div class="pricing-wrap">
        <div class="pricing-box light">
          <h3 class="big-title">{{ $t('pricing-price-1') }}</h3>
          <p class="regular-text">{{ $t('pricing-price-1-desc') }}</p>
          <div class="feature">
            <img src="@/assets/img/feature-12-1.png" alt="Aucune limitation" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-1') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-1.png" alt="Aucune limitation" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-2') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-2.png" alt="Créez vos publicités" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-3') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-11-1.png" alt="Créez vos publicités" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-4') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-7.png" alt="Connectez-vous avec FBM" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-5') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-8.png" alt="Exportez pour Google Ads Editor" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-6') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-5.png" alt="Recevez des commentaires" />
            <p class="regular-text">{{ $t('pricing-price-1-feat-7') }}</p>
          </div>
          <a href="https://app.showadz.io/create-account" target="_blank" rel="noopener noreferrer" class="cta-bt">
            <p>{{ $t('pricing-price-1-cta') }}</p>
          </a>
        </div>

        <div class="pricing-box dark">
          <h3 class="big-title" v-html="$t('pricing-price-2')"></h3>
          <p class="regular-text">{{ $t('pricing-price-2-desc') }}</p>
          <div class="feature">
            <img src="@/assets/img/feature-6-2.png" alt="Aucune limitation" />
            <p class="regular-text">{{ $t('pricing-price-2-feat-1') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-12-2.png" alt="Aucune limitation" />
            <p class="regular-text">{{ $t('pricing-price-2-feat-2') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-1-2.png" alt="Travailler en équipe" />
            <p class="regular-text">{{ $t('pricing-price-2-feat-3') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-9-2.png" alt="Travailler en équipe" />
            <p class="regular-text">{{ $t('pricing-price-2-feat-4') }}</p>
          </div>
          <div class="feature">
            <img src="@/assets/img/feature-11-2.png" alt="Créez vos publicités" />
            <p class="regular-text">{{ $t('pricing-price-2-feat-5') }}</p>
          </div>
          <router-link :to="{ name: 'contact-' + $route.meta.lang }" class="cta-bt">
            <p>{{ $t('pricing-price-2-cta') }}</p>
          </router-link>
        </div>

        <div class="pricing-box light">
          <h3 class="big-title">{{ $t('pricing-price-3') }}</h3>
          <p class="regular-text">
            {{ $t('pricing-price-3-desc') }}
          </p>
          <router-link :to="{ name: 'contact-' + $route.meta.lang }" class="cta-bt">
            <p>{{ $t('pricing-price-3-cta') }}</p>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'vue-flickity'

export default {
  components: {
    Flickity,
  },
  head() {
    return {
      title: {
        inner: 'Expérience client',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        { property: 'og:url', content: `https://showadz.io` },
        { property: 'og:type', content: `Website` },
        {
          property: 'og:description',
          content: `Une approche orientée sur l'expérience client et le travail d'équipe pour les experts en marketing numérique.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/og-image-showadz.jpg?alt=media&token=359400dd-56d7-48f4-8ae5-04ae30ecda7c',
        },
      ],
    }
  },

  data() {
    return {
      sliderNumber: 0,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
      },
    }
  },

  methods: {
    next() {
      this.$refs.flickity.next()
    },

    previous() {
      this.$refs.flickity.previous()
    },
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    if (window.location.href.includes('showadz.com')) {
      window.location.href = 'https://showadz.io/'
    }

    if (this.$route && this.$route.hash) {
      const selector = this.$route.hash

      const $zone = document.querySelector(selector)

      const topPos = $zone.getBoundingClientRect().top + window.scrollY

      // this changes the scrolling behavior to "smooth"
      window.scroll({
        top: topPos,
        behavior: 'smooth',
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typographie.scss';
@import '@/theme/button.scss';

.hero-2 {
  padding: 5vw 8vw 0vw 8vw;

  .data {
    text-align: center;
    margin: 0 auto;

    .big-title {
      margin-bottom: 1.5vw;
      font-size: 3vw;
    }

    .regular-text {
      font-size: 18px;
      margin-bottom: 2vw;
    }

    .cta-bt {
      display: inline-block;
      padding: 15px 20px 18px 20px;
      margin-bottom: 2.5vw;

      p {
        font-size: 16px;
      }
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.5vw;

      img {
        width: 40px;
        margin: 0 10px;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .data {
      .big-title {
        font-size: 30px;
      }
    }
  }

  @media screen and (max-width: 570px) {
    padding: 10vw 5vw 0vw 5vw;
    .data {
      .big-title {
        font-size: 20px;
        margin-bottom: 25px;
      }
      .regular-text {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 25px;
      }
      .cta-bt {
        padding: 12px 15px 12px 15px;
        margin-bottom: 25px;

        p {
          font-size: 14px;
        }
      }
      .logos {
        margin-bottom: 25px;

        img {
          width: 30px;
          margin: 0 10px;
        }
      }
    }
  }
}

.browser {
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);

  header {
    background-color: #fff;
    padding: 12px;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .circle {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;

      &.red {
        background-color: #ed6a5f;
      }
      &.yellow {
        background-color: #f6be4f;
      }
      &.green {
        background-color: #61c554;
      }
    }
  }

  .content {
    padding: 0 5px 5px 5px;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .slide {
      width: 100%;
      position: relative;

      img {
        width: 100%;
      }

      .video-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        background: linear-gradient(to right, #2e7df2, #8a23f1);
        opacity: 0.05;
      }

      video {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 690px) {
    header {
      padding: 8px;
      .circle {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 5px;
      }
    }
  }
}

.arguments-wrap {
  padding: 5vw 8vw 7vw 8vw;
  display: flex;
  justify-content: space-between;

  .argument {
    width: 32%;

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .medium-title {
      margin-bottom: 20px;
      font-size: 1.8vw;
    }
  }

  @media screen and (max-width: 980px) {
    padding: 5vw;
    flex-wrap: wrap;
    .argument {
      width: 100%;
      margin-bottom: 40px;

      .medium-title {
        font-size: 25px;
      }
    }
  }

  @media screen and (max-width: 570px) {
    .argument {
      .medium-title {
        font-size: 20px;
      }
    }
  }
}

.temoignages {
  width: 100%;
  padding: 3vw 8vw 8vw 5vw;
  position: relative;

  .swipe {
    position: absolute;
    top: -30px;
    right: 5vw;
    width: 30px;
    display: none;
  }

  .arrows-wrap {
    position: absolute;
    right: 0;
    bottom: 15vw;
    width: 12vw;
    height: 12vw;
    background: linear-gradient(to right, #2bb0c5, #6bd8ba);
    z-index: 10;

    button {
      border: 0;
      background-color: transparent;
    }

    .left {
      width: 20px;
      position: absolute;
      top: 6vw;
      left: 4vw;
      cursor: pointer;
    }
    .right {
      width: 20px;
      position: absolute;
      top: 6vw;
      left: 8vw;
      cursor: pointer;
    }
  }

  .carousel {
    width: 100%;
    position: relative;
    z-index: 100;

    .carousel-cell {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;

      .img {
        position: absolute;
        top: 0;
        left: 0;

        .gradient {
          width: 12vw;
          height: 12vw;
          background: linear-gradient(to right, #2e7df2, #8a23f1);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          opacity: 0.4;
        }

        .dots {
          width: 5vw;
          position: absolute;
          top: 2vw;
          left: 2vw;
          z-index: 20;
        }

        .user {
          width: 100%;
          max-width: 27vw;
          position: relative;
          z-index: 10;
          margin-top: 4.5vw;
          margin-left: 4.5vw;
        }
      }

      .data {
        width: 70%;
        .top {
          width: 100%;
          background-color: #fff;
          padding: 4.5vw 4.5vw 4.5vw 10vw;
          min-height: 420px;

          .title {
            display: flex;
            align-items: center;

            img {
              width: 25px;
              margin-left: 15px;
            }
          }

          .top-argument {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .argument {
              margin: 2vw 0;
              .number {
                font-weight: 700;
                color: #4628ff;
                font-size: 30px;
                margin-bottom: 5px;
              }
              .regular-text {
                line-height: 1.2;
              }

              .stars-wrap {
                display: flex;
                align-items: center;
                height: 45px;

                .stars {
                  display: flex;
                  align-items: center;
                  margin-right: 10px;
                  width: 75px;

                  img {
                    width: 15px;
                  }
                }

                .note {
                  color: #4628ff;
                }
              }
            }
          }

          .testimonial-txt {
            width: 100%;
            font-style: italic;
            line-height: 1.2;
          }
        }

        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 1vw 0 10vw 9.5vw;

          .company {
            width: 5vw;
          }

          .txt {
            margin-left: 15px;
            .regular-text {
              width: 100%;
              line-height: 1;

              &.poste {
                font-size: 18px;
                color: $dark-blue;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    padding: 3vw 5vw 8vw 5vw;
    .swipe {
      display: block;
    }
    .arrows-wrap {
      display: none;
    }
    .carousel {
      .carousel-cell {
        .img {
          display: none;
        }
        .data {
          width: 100%;
          .top {
            padding: 20vw 7vw 7vw 7vw;
            .testimonial-txt {
              padding-top: 20px;
            }
            .title {
              padding-bottom: 20px;
              position: absolute;
              top: 0;
              left: 0;

              .user {
                margin-left: 0;
                width: auto;
                height: 20vw;
                margin-right: 25px;
              }
            }
          }
          .bottom {
            padding: 0;
            padding-top: 20px;
            .company {
              width: 50px;
            }
            .txt {
              .regular-text {
                &.poste {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    padding: 3vw 8vw 15vw 8vw;
    .swipe {
      top: -40px;
      right: 8vw;
    }
    .carousel {
      .carousel-cell {
        .data {
          .top {
            padding: 30vw 7vw 7vw 7vw;
            .top-argument {
              flex-wrap: wrap;

              .argument {
                width: 50%;
              }
            }
            .title {
              .user {
                margin-left: 0;
                width: auto;
                height: 30vw;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.personas {
  text-align: center;
  padding: 0 8vw 5vw 8vw;

  .wave {
    width: 40px;
    display: inline-block;
    margin: 25px 0 15px 0;
  }

  .regular-text {
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 5vw;
    font-size: 18px;
  }

  img {
    width: 100%;

    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 0 5vw 5vw 5vw;
  }

  @media screen and (max-width: 980px) {
    padding: 0 5vw 10vw 5vw;

    .big-title {
      padding: 0 5vw;
    }

    .regular-text {
      font-size: 14px;
    }

    img {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        position: relative;
        left: 9px;
      }
    }
  }
}

.pricing {
  text-align: center;
  padding: 0 8vw 5vw 8vw;

  .wave {
    width: 40px;
    display: inline-block;
    margin: 25px 0 15px 0;
  }

  .regular-text {
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 5vw;
    font-size: 18px;
  }

  .cta-bt {
    display: inline-block;
    margin: 0 auto;
    margin-top: 3vw;
    padding: 15px 20px 18px 20px;
    p {
      font-size: 16px;
    }
  }

  .pricing-wrap {
    display: flex;
    justify-content: center;

    .pricing-box {
      width: 500px;
      margin: 0 10px;
      text-align: left;
      padding: 3vw;
      padding-bottom: 8vw;
      position: relative;

      &.light {
        background-color: #eaebef;
        .feature {
          .regular-text {
            line-height: 1.2;
          }
        }
      }

      &.dark {
        background-color: $dark-blue;

        .big-title {
          color: #fff;
        }

        .regular-text {
          color: #8694a7;
        }

        .feature {
          .regular-text {
            color: #fff;
            line-height: 1.2;
          }
        }

        .note {
          background-color: #353b46;
          .regular-text {
            color: #fff;
          }
        }
      }

      .big-title {
        margin-bottom: 10px;

        span {
          font-size: 60%;
        }
      }

      .regular-text {
        font-size: 16px;
        max-width: none;
        padding: 0;
        margin: 0;
        margin-bottom: 25px;

        a {
          color: #4628ff;
        }
      }

      .note {
        background-color: #fff;
        padding: 15px 20px 20px 20px;
        text-align: center;
        margin-top: 25px;
        .regular-text {
          font-size: 16px;
          max-width: none;
          padding: 0;
          margin: 0;
          line-height: 1;
        }
      }

      .cta-bt {
        width: calc(100% - 6vw);
        text-align: center;
        margin-top: 20px;
        position: absolute;
        bottom: 3vw;
        left: 3vw;
      }

      .feature {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .regular-text {
          padding: 0;
          max-width: none;
          margin: 0;
          font-size: 16px;
        }

        img {
          width: 40px;
          margin-right: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 0 5vw 5vw 5vw;
  }

  @media screen and (max-width: 1000px) {
    .pricing-wrap {
      flex-wrap: wrap;
      .pricing-box {
        width: 100%;
        padding: 7vw;
        padding-bottom: 20vw;

        .cta-bt {
          width: calc(100% - 14vw);
          padding: 12px 15px 12px 15px;
          margin-top: 30px;
          bottom: 7vw;
          left: 7vw;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .regular-text {
      font-size: 14px;
    }
    .big-title {
      font-size: 24px;
    }
    .pricing-box {
      .big-title {
        font-size: 30px;
      }
    }
  }
}

.strategy {
  text-align: center;
  padding: 0 8vw 5vw 8vw;

  .strat-wrap {
    width: 100%;
    background-color: $dark-blue;
    padding: 5vw 20vw;

    h2 {
      font-size: 20px;
      opacity: 0.8;
    }

    .wave {
      width: 40px;
      display: inline-block;
      margin: 1vw 0 0.5vw 0;
    }

    .big-title {
      color: #fff;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 0 5vw 5vw 5vw;
  }

  @media screen and (max-width: 690px) {
    .strat-wrap {
      padding: 10vw 10vw;

      h2 {
        font-size: 16px;
      }

      .wave {
        margin: 3vw 0 1vw 0;
      }
    }
  }
}

.features-wrap {
  padding: 0vw 8vw 5vw 8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .feature {
    width: 32%;
    margin-bottom: 5vw;

    img {
      width: 60px;
      margin-bottom: 20px;
    }

    .medium-title {
      margin-bottom: 20px;
      font-size: 22px;
    }
  }

  @media screen and (max-width: 980px) {
    padding: 0vw 5vw 7vw 5vw;
    .feature {
      width: 49%;
      img {
        width: 50px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    .feature {
      width: 100%;
    }
  }

  @media screen and (max-width: 570px) {
    .feature {
      .medium-title {
        font-size: 20px;
      }
    }
  }
}
</style>
