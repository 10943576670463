const trans = {
    en: {
        'menu-1': "Benefits",
        'menu-2': "Features",
        'menu-3': "Pricing",
        'menu-4': "Demo",
        'connexion': "Sign in",
        'cta-short': "Create your account",

        'intro-title': "Create and preview your<br>digital ads easily",
        'intro-desc': "Offer a superior experience to your clients and optimize the productivity of your experts",
        'cta': "Create your free account",

        'argument-1-title': "Stop showing<br>your ads in Excel",
        'argument-1-desc': "You've always wanted to do without Excel when it comes to presenting your ads? Try Showadz and increase the productivity of your teams while offering a better experience to your clients.",
        'argument-2-title': "Preview your ads<br>in their environment",
        'argument-2-desc': "Preview your ads in context on Facebook and Google. Ads will always be in context, allowing your teams and clients to properly judge the relevance of the ads.",
        'argument-3-title': "Share a single link<br>for all your ads",
        'argument-3-desc': "Share a single approval link for all your ads. Save time and provide better communication tools to your teams and clients.",

        'testimonial-hours': "Hours saved<br>per year",
        'testimonial-clients': "Clients introduced<br>to Showadz",
        'testimonial-score': "Overall platform<br>appreciation",
        'testimonial-audrey': "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae sapien pellentesque habitant morbi tristique senectus et. Phasellus vestibulum lorem sed risus ultricies. Massa enim nec dui nunc mattis enim ut tellus. Scelerisque varius morbi enim nunc faucibus a.",
        'testimonial-audrey-poste': "Senior Digital Media Strategist",
        'testimonial-benoit': "Nous utilisons Showadz de façon journalière dans nos processus de création de visuels pour Facebook et Instagram. Showadz nous apporte un gain de temps ainsi qu’une facilité dans la création des annonces non négligeable ! Chacun des membres de l’équipe peut facilement interagir avec la plateforme. En interne, cela facilite nos processus à tous les niveaux. De plus, l’outil de prévisualisation des annonces de Showadz a fait l’unanimité chez l’ensemble de nos clients.",
        'testimonial-benoit-poste': "Founder and web consultant",
        'testimonial-sophie': "The platform helps me enormously in my daily work. In addition to gaining a lot of time and efficiency, this collaborative tool allows clients to directly visualize their ads. The Showadz team is very responsive, attentive and is not afraid to constantly improve this tool to make sure it is up to date. I recommend without hesitation.",
        'testimonial-sophie-poste': "Founder and Digital Strategist",
        'testimonial-manon': "I've been using Showadz for a long time now and it's nice to see the evolution of the platform based on the reviews of its own users. In addition, we receive excellent feedback from our clients who see much better rendering of the ads in a very user-friendly interface.  All this saves us an enormous amount of time when it comes to uploads and even prevents us from making oversights.",
        'testimonial-manon-poste': "Lead Digital Marketing Strategist",
        'testimonial-colas': "With many campaign uploads in my professional career, the Showadz tool has been able to address many of the issues that all campaign managers face. Showadz allows me to save time in my uploads, to avoid mistakes (copy and paste of ad texts, adding the wrong image with the wrong text), and so on. But Showadz also made it easier for my clients to understand ads and ad placements. They get to approve the ads in real and familiar contexts (e.g. in the Facebook feed, on Google, etc.).",
        'testimonial-colas-poste': "Founder and Data Strategist",

        'approche-title': "An approach that improves the client experience",
        'approche-desc': "The client experience is at the core of our concerns. Showadz aims to simplify the collaboration between marketing experts and their clients.",

        'features-desc': "An easy-to-use advertising context platform",
        'features-1-title': "Manage your clients and your campaigns",
        'features-1-desc': "Easily organize the records and campaigns of your clients by advertising platform.",
        'features-2-title': "Create your own ads",
        'features-2-desc': "Create your ads in a few clicks while always ensuring that you meet all the technical specifications of Google and Facebook.",
        'features-3-title': "Reframe your images",
        'features-3-desc': "Easily reframe your images in the recommended formats for advertising platforms.",
        'features-4-title': "In-context preview",
        'features-4-desc': "Preview your Facebook and Google ads in their real environment.",
        'features-5-title': "Share with your clients",
        'features-5-desc': "Help your clients to better understand digital ads. Reduce management and approval time.",
        'features-6-title': "Get feedback",
        'features-6-desc': "Centralize communications on Showadz. Avoid long email chains and receive approvals faster.",
        'features-7-title': "Sign in with FBM",
        'features-7-desc': "Automatically sync your ads on Facebook Business Manager and avoid copy and paste errors.",
        'features-8-title': "Export to Google Ads Editor",
        'features-8-desc': "Export a CSV file from your Showadz account and import your ads into Google Ads Editor.",
        'features-9-title': "Work in team",
        'features-9-desc': "Invite your clients, partners and colleagues to collaborate on projects.",

        'pricing-title': "Make the most of our launching offer",
        'pricing-desc': "An annual fee that will let you take full advantage of Showadz and benefit from updates.",

        'pricing-price-1': "$0",
        'pricing-price-1-desc': "Create your free account and enjoy the following features:",
        'pricing-price-1-feat-1': "1 client",
        'pricing-price-1-feat-2': "1 campaign",
        'pricing-price-1-feat-3': "4 Facebook ads",
        'pricing-price-1-feat-4': "Unlimited Google ads",
        'pricing-price-1-feat-5': "Sign in with FBM",
        'pricing-price-1-feat-6': "Export to Google Ads Editor",
        'pricing-price-1-feat-7': "Comments",
        'pricing-price-1-cta': "Create your account",

        'pricing-price-2': "$495 <span>/ year</span>",
        'pricing-price-2-desc': "Enjoy the full functionality of Showadz:",
        'pricing-price-2-feat-1': "Free account +",
        'pricing-price-2-feat-2': "10 clients",
        'pricing-price-2-feat-3': "5 campaigns per client",
        'pricing-price-2-feat-4': "Invite your colleagues and clients",
        'pricing-price-2-feat-5': "Unlimited Facebook and Google ads",
        'pricing-price-2-cta': "Buy now",

        'pricing-price-3': "On demand",
        'pricing-price-3-desc': "You've reached the limits of your package? Contact us to get a customized package that fits your needs.",
        'pricing-price-3-cta': "Contact us",

        'footer-title': "Our mission",
        'footer-desc': "Optimize time and deliverables for digital marketing experts and their clients.",
        'footer-cta': "Book a demo",
        'footer-julien': "<p><span>Julien Aubrey</span><br>Co-founder<br>Lead design</p>",
        'footer-vincent': "<p><span>Vincent Bourdeau</span><br>Co-founder<br>Lead dev</p>",
        'footer-copyrights': "© Showadz - All rights reserved.",

        'contact-title': "Enjoy the full functionality of Showadz",
        'contact-desc': "Fill the form and we'll take care of you",
        'thanks-title': "Thank you!",
        'thanks-desc': "We will respond as soon as possible",
        'thanks-cta': "Back",
        'form-name': "First and last name",
        'form-business': "Company",
        'form-mail': "Mail",
        'form-interest': "Interest",
        'form-interest-1': "Launching offer $495/year",
        'form-interest-2': "On demand",
        'form-send': "Send",
    },
    fr: {
        'menu-1': "Avantages",
        'menu-2': "Fonctionnalités",
        'menu-3': "Tarification",
        'menu-4': "Démo",
        'connexion': "Connexion",
        'cta-short': "Créez votre compte",

        'intro-title': "Créez et prévisualisez facilement<br>vos publicités numériques",
        'intro-desc': "Offrez une meilleure expérience à vos clients et optimisez le temps de vos experts.",
        'cta': "Créez votre compte gratuitement",

        'argument-1-title': "Arrêtez de présenter vos publicités dans Excel",
        'argument-1-desc': "Vous avez toujours souhaité vous passer d’Excel pour la présentation de vos publicités ? Essayez Showadz et augmentez le rendement de vos équipes tout en offrant une meilleure expérience à vos clients.",
        'argument-2-title': "Prévisualisez vos publicités dans leur environnement",
        'argument-2-desc': "Prévisualisez vos annonces en contexte sur Facebook et Google. Vous aurez toujours une mise en contexte qui permettra à vos équipes et à vos clients de bien juger de la pertinence des annonces publicitaires.",
        'argument-3-title': "Partagez un seul lien pour l'ensemble de vos publicités",
        'argument-3-desc': "Partagez un seul lien d’approbation pour la totalité de vos annonces. Sauvez du temps et offrez de meilleurs outils de communication à vos équipes et à vos clients.",

        'testimonial-hours': "Heures sauvées<br>par année",
        'testimonial-clients': "Clients introduits<br>à Showadz",
        'testimonial-score': "Appréciation globale<br>de la plateforme",
        'testimonial-audrey': "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae sapien pellentesque habitant morbi tristique senectus et. Phasellus vestibulum lorem sed risus ultricies. Massa enim nec dui nunc mattis enim ut tellus. Scelerisque varius morbi enim nunc faucibus a.",
        'testimonial-audrey-poste': "Stratège média numérique senior",
        'testimonial-benoit': "Nous utilisons Showadz de façon journalière dans nos processus de création de visuels pour Facebook et Instagram. Showadz nous apporte un gain de temps ainsi qu’une facilité dans la création des annonces non négligeable ! Chacun des membres de l’équipe peut facilement interagir avec la plateforme. En interne, cela facilite nos processus à tous les niveaux. De plus, l’outil de prévisualisation des annonces de Showadz a fait l’unanimité chez l’ensemble de nos clients.",
        'testimonial-benoit-poste': "Fondateur et consultant web",
        'testimonial-sophie': "La plateforme m’aide énormément dans mon travail quotidien. En plus de faire gagner beaucoup en temps et en efficacité, cet outil collaboratif permet aux clients de directement bien visualiser leurs publicités. L’équipe de Showadz est très réactive, à l’écoute et n’a pas peur d’améliorer sans cesse cet outil pour s’assurer d’être à jour. Je recommande sans hésiter.",
        'testimonial-sophie-poste': "Fondatrice et stratège numérique",
        'testimonial-manon': "J'utilise Showadz depuis un long moment déjà et c'est agréable de voir l'évolution de la plateforme selon les commentaires de ses propres utilisateurs. De plus, nous recevons d'excellents commentaires de nos clients qui visualisent beaucoup mieux le rendu des publicités dans une interface très user-friendly. Le tout nous fait sauver énormément de temps en mise en ligne et nous évite même des erreurs d'inattention.",
        'testimonial-manon-poste': "Lead Stratège Marketing numérique",
        'testimonial-colas': "Avec de nombreuses mises en ligne de campagnes dans ma carrière professionnelle, l’outil Showadz a su répondre à de nombreux enjeux auxquels tous les gestionnaires de campagnes font face. Showadz me permet d’économiser du temps dans mes mises en ligne, d’éviter les erreurs (copier/coller du texte des annonces, ajout de la mauvaise image avec le mauvais texte), et j’en passe. Mais Showadz a également facilité la compréhension des annonces et des placements publicitaires pour mes clients. Ils approuvent les annonces dans un contexte réel et familier (p.ex dans le feed Facebook, sur Google, etc.).",
        'testimonial-colas-poste': "Fondateur et facilitateur en exploitation des données",

        'approche-title': "Une approche qui améliore l’expérience client",
        'approche-desc': "L’expérience client est au centre de nos préoccupations. Showadz a pour but de simplifier la collaboration entre les experts en marketing et leurs clients.",

        'features-desc': "Une plateforme de mise en contexte publicitaire simple d’utilisation.",
        'features-1-title': "Gérez vos clients et vos campagnes",
        'features-1-desc': "Organisez facilement les dossiers de vos clients et leurs campagnes par plateforme publicitaire.",
        'features-2-title': "Créez vos publicités",
        'features-2-desc': "Créez vos publicités en quelques clics et assurez-vous de toujours respecter les spécifications techniques de Google et Facebook.",
        'features-3-title': "Recadrez vos images",
        'features-3-desc': "Recadrez facilement vos images dans les formats recommandés par les plateformes publicitaires.",
        'features-4-title': "Prévisualisez en contexte",
        'features-4-desc': "Prévisualisez vos annonces Facebook et Google dans leur environnement réel.",
        'features-5-title': "Partagez à vos clients",
        'features-5-desc': "Aidez vos clients à mieux comprendre les publicités numériques. Réduisez le temps de gestion et d'approbation.",
        'features-6-title': "Recevez des commentaires",
        'features-6-desc': "Centralisez les communications sur Showadz. Évitez les longues chaînes de courriels et recevez les approbations plus rapidement.",
        'features-7-title': "Connectez-vous avec FBM",
        'features-7-desc': "Synchronisez automatiquement vos publicités sur Facebook Business Manager et évitez les erreurs de copier-coller.",
        'features-8-title': "Exportez pour Google Ads Editor",
        'features-8-desc': "Exportez un fichier CSV à partir de votre compte Showadz et importez vos annonces dans Google Ads Editor.",
        'features-9-title': "Travaillez en équipe",
        'features-9-desc': "Invitez vos clients, vos partenaires et vos collègues à collaborer sur les projets.",

        'pricing-title': "Profitez de notre offre de lancement",
        'pricing-desc': "Une tarification annuelle qui vous permettra de profiter pleinement de Showadz et de bénéficier des mises à jour.",

        'pricing-price-1': "0$",
        'pricing-price-1-desc': "Créez votre compte gratuitement et profitez des fonctionnalités suivantes :",
        'pricing-price-1-feat-1': "1 client",
        'pricing-price-1-feat-2': "1 campagne",
        'pricing-price-1-feat-3': "4 publicités Facebook",
        'pricing-price-1-feat-4': "Publicités Google illimitées",
        'pricing-price-1-feat-5': "Connexion avec FBM",
        'pricing-price-1-feat-6': "Exportation pour Google Ads Editor",
        'pricing-price-1-feat-7': "Commentaires",
        'pricing-price-1-cta': "Créez votre compte",

        'pricing-price-2': "495$ <span>/ année</span>",
        'pricing-price-2-desc': "Profitez pleinement des fonctionnalités de Showadz :",
        'pricing-price-2-feat-1': "Compte gratuit +",
        'pricing-price-2-feat-2': "10 clients",
        'pricing-price-2-feat-3': "5 campagnes par client",
        'pricing-price-2-feat-4': "Invitez vos collègues et vos clients",
        'pricing-price-2-feat-5': "Publicités Facebook et Google illimitées",
        'pricing-price-2-cta': "Achetez maintenant",

        'pricing-price-3': "Sur mesure",
        'pricing-price-3-desc': "Vous avez atteint les limitations de votre forfait ? Contactez-nous pour obtenir un forfait sur mesure qui correspond à vos besoins.",
        'pricing-price-3-cta': "Contactez-nous",

        'footer-title': "Notre mission",
        'footer-desc': "Optimiser le temps et les livrables des experts en marketing numérique et leurs clients.",
        'footer-cta': "Planifiez une démo",
        'footer-julien': "<p><span>Julien Aubrey</span><br>Co-fondateur<br>Lead design</p>",
        'footer-vincent': "<p><span>Vincent Bourdeau</span><br>Co-fondateur<br>Lead dev</p>",
        'footer-copyrights': "© Showadz - Tous droits réservés.",

        'contact-title': "Profitez d'une expérience complète",
        'contact-desc': "Remplissez le formulaire et on s'occupe de vous.",
        'thanks-title': "Merci!",
        'thanks-desc': "Nous vous répondrons dans les plus brefs délais",
        'thanks-cta': "Retour",
        'form-name': "Prénom et nom",
        'form-business': "Entreprise",
        'form-mail': "Courriel",
        'form-interest': "Intérêt",
        'form-interest-1': "Offre de lancement 495$/année",
        'form-interest-2': "Forfait sur mesure",
        'form-send': "Envoyer",
    }
}

export default trans
