<template>
  <header class="navbar">
    <div class="left">
      <router-link :to="{ name: 'home-' + $route.meta.lang }"><img src="@/assets/img/logo.svg" class="logo" alt="Logo Showadz" /></router-link>
      <nav v-if="$route.name.includes('home')">
        <a v-scroll-to:-50 href="#avantages">{{ $t('menu-1') }}</a>
        <a v-scroll-to:-480 href="#features">{{ $t('menu-2') }}</a>
        <a v-scroll-to:-150 href="#pricing">{{ $t('menu-3') }}</a>
        <a href="#" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/showadz/30min'});return false;">{{ $t('menu-4') }}</a>
      </nav>
      <nav v-else>
        <router-link :to="{ name: 'home-' + $route.meta.lang, hash: '#avantages' }">{{ $t('menu-1') }}</router-link>
        <router-link :to="{ name: 'home-' + $route.meta.lang, hash: '#features' }">{{ $t('menu-2') }}</router-link>
        <router-link :to="{ name: 'home-' + $route.meta.lang, hash: '#pricing' }">{{ $t('menu-3') }}</router-link>
        <a href="#" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/showadz/30min'});return false;">{{ $t('menu-4') }}</a>
      </nav>
    </div>
    <div class="actions-wrap">
      <a href="https://app.showadz.io/" target="_blank" rel="noopener noreferrer">{{ $t('connexion') }}</a>
      <a href="https://app.showadz.io/create-account" target="_blank" rel="noopener noreferrer" class="cta-bt">
        <p>{{ $t('cta-short') }}</p>
      </a>
      <router-link :to="{ name: $route.name.replace('-fr', '-en') }" class="lang" v-if="$route.meta.lang === 'fr'">EN</router-link>
      <router-link :to="{ name: $route.name.replace('-en', '-fr') }" class="lang" v-if="$route.meta.lang === 'en'">FR</router-link>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  .actions-wrap {
    display: flex;
    align-items: center;

    a {
      color: $dark-grey;
      font-size: 16px;
      text-decoration: none;
      margin-right: 20px;

      &.lang {
        margin-right: 0;
      }
    }
  }

  .logo {
    width: 170px;
  }

  .left {
    display: flex;
    align-items: center;

    nav {
      margin-left: 10px;
    }

    a {
      color: $dark-grey;
      font-size: 16px;
      text-decoration: none;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 12px 5vw;

    .logo {
      width: 125px;
    }

    .actions-wrap {
      a {
        font-size: 14px;
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 915px) {
    position: relative;
    padding: 20px 5vw;

    .left {
      nav {
        display: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .actions-wrap {
      .cta-bt {
        display: none;
      }

      a {
        margin-right: 0;

        &.lang {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
